<template>
  <div class="main_wrapper">
    <div class="cover_image_wrapper">
      <h2 class="cover_title">{{ $t('portal.request_duplicate_certificate') }}</h2>
      <img src="../../../../assets/images/portal/cover-img.jpg" alt="" />
    </div>
    <div class="breadcrumb_wrapper">
      <b-container>
        <b-row>
          <b-col>
            <nav aria-label="breadcrumb">
              <ol class="breadcrumb">
                <li class="breadcrumb-item"><router-link to="/portal/home">{{ $t('portal.home') }}</router-link></li>
                <li class="breadcrumb-item active"><a to="#">{{ $t('portal.request_duplicate_certificate') }}</a></li>
              </ol>
            </nav>
          </b-col>
        </b-row>
      </b-container>
    </div>
    <div class="menu_bar_wrapper">
      <b-container>
        <b-row>
          <b-col sm="12">
            <iq-card>
              <template v-slot:headerTitle>
                <h4 class="card-title">{{ $t('portal.request_duplicate_certificate') }}</h4>
              </template>
              <template v-slot:body>
                <div class="all_service_wrapper venue">
                  <b-overlay :show="load">
                    <!-- <div>
                      <b-alert show dismissible variant="success" v-if="showMessage">
                        {{$t('globalTrans.we_notify_you_in_message')}} <b>&rArr;</b>
                      </b-alert>
                    </div> -->
                    <b-row>
                  <!-- <b-col>
                    <list-report-head :base-url="trainingElearningServiceBaseUrl" uri="/config/report-head/detail"
                      :org-id="orgiId">
                      <div class="titleDiv">
                      </div>
                    </list-report-head>
                  </b-col> -->
                </b-row>
                <b-col lg="12">
                <div id="printData">
                  <b-row>
                    <div class="card card w-100 text-center">
                      <div class="card-inside-title font-weight-bold text-capitalize p-1 bg-light text-black">
                        <h5 class="font-weight-bold text-capitalize text-center bg-light p-1 badge-light"
                          style="color: green!important;font-size: 20px!important;"> {{ $t('globalTrans.cancel') }}</h5>
                      </div>
                    </div>
                  </b-row>
                 </div>
                </b-col>
                  </b-overlay>
                </div>
              </template>
            </iq-card>
          </b-col>
        </b-row>
      </b-container>
    </div>
  </div>
</template>
<style>
  #form fieldset {
    border: 1px solid #8f7c7c!important;
    float: left!important;
    margin-bottom: 25px!important;
    padding: 10px 0!important;
    width: 99.5%!important;
  }
  #form legend {
    background: #02813e none repeat scroll 0 0!important;
    border: 1px solid #e5e5e5!important;
    color: #fff!important;
    font-size: 13px!important;
    font-weight: 700!important;
    margin-bottom: 0!important;
    margin-left: 14px!important;
    padding: 4px 10px!important;
    width: 31%!important;
    border-radius: 0!important;
  }
  .alert .close {
    color: red !important;
  }
</style>
<script>
import RestApi, { commonServiceBaseUrl, trainingElearningServiceBaseUrl } from '../../../../config/api_config'
import Common from '@/mixins/portal/common'
import { duplicateCertificateApiPaymentCancel } from '../../api/routes'
import flatpickr from 'flatpickr'
import Vue from 'vue'
import VueHtmlToPaper from 'vue-html-to-paper'
Vue.use(VueHtmlToPaper)
const TWO_MINITES = 60 * 2
const today = new Date().toISOString().substr(0, 10)

export default {
  components: {
  },
  mixins: [Common],
  created () {
    this.paymentStatusUpdate()
    this.getFeeAmount()
    // this.duplicateCertificateFeeList()
    // this.getVenueCondition()
  },
  data () {
    return {
      current_date: today,
      isLoading: false,
      isLoadingData: false,
      load: false,
      loadModal: false,
      commonServiceBaseUrl: commonServiceBaseUrl,
      saveBtnName: this.id ? this.$t('globalTrans.update') : this.$t('globalTrans.save'),
      profile: {},
      officeTypeList: [],
      officeList: [],
      designationList: [],
      venueCondition: '',
      reload: false,
      showMessage: false,
      countShow: false,
      TWO_MINITES: TWO_MINITES,
      timer: 0
    }
  },
  filters: {
    minutesAndSeconds (value) {
      var minutes = Math.floor(parseInt(value, 10) / 60)
      var seconds = parseInt(value, 10) - minutes * 60
      return `${minutes}:${seconds}`
    }
  },
  computed: {
    professionTypeList: function () {
      return [
        {
          value: 1,
          text: this.$i18n.locale === 'bn' ? 'কৃষি মন্ত্রণালয়/আওতাধীন সংস্থা' : 'MoA/Organization under MoA',
          text_en: 'MoA/Organization under MoA',
          text_bn: 'কৃষি মন্ত্রণালয়/আওতাধীন সংস্থা'
        },
        {
          value: 2,
          text: this.$i18n.locale === 'bn' ? 'অন্যান্য সরকারী সংস্থা' : 'Other Government Organization',
          text_en: 'Other Government Organization',
          text_bn: 'অন্যান্য সরকারী সংস্থা'
        },
        {
          value: 3,
          text: this.$i18n.locale === 'bn' ? 'প্রাইভেট প্রতিষ্ঠান' : 'Private Company',
          text_en: 'Private Company',
          text_bn: 'প্রাইভেট প্রতিষ্ঠান'
        }
      ]
    },
    subjectTypeList: function () {
      return [
        {
          value: 1,
          text: this.$i18n.locale === 'bn' ? 'সেমিনার' : 'Seminer',
          text_en: 'Seminer',
          text_bn: 'সেমিনার'
        },
        {
          value: 2,
          text: this.$i18n.locale === 'bn' ? 'প্রশিক্ষণ' : 'Training',
          text_en: 'Training',
          text_bn: 'প্রশিক্ষণ'
        },
        {
          value: 3,
          text: this.$i18n.locale === 'bn' ? 'কর্মশালা' : 'Workshop',
          text_en: 'Private Company',
          text_bn: 'Workshop'
        }
      ]
    },
    jobTypeList () {
      return [
        {
          value: 1,
          text: this.$i18n.locale === 'bn' ? 'ক্যাডার' : 'Cader',
          text_en: 'Cader',
          text_bn: 'ক্যাডার'
        },
        {
          value: 2,
          text: this.$i18n.locale === 'bn' ? 'নন-ক্যাডার' : 'Non-Cader',
          text_en: 'Non-Cader',
          text_bn: 'নন-ক্যাডার'
        }
      ]
    },
    orgList: function () {
      return this.$store.state.Portal.commonObj.organizationProfileList.filter(item => item.status === 0)
    },
    duplicateCertificateFeeList: function () {
      return this.$store.state.Portal.trainObj.duplicateCertificateFeeList.map((obj, index) => {
        if (this.$i18n.locale === 'bn') {
          const n = obj.text_bn
          if (typeof n === 'string') {
            const num = n.replace(/\d/g, d => '০১২৩৪৫৬৭৮৯'[d])
            return { value: obj.value, text: num }
          } else {
            return { value: obj.value, text: '' }
          }
        } else {
          return { value: obj.value, text: obj.text_en }
        }
      })
    },
    govOrPvt: function () {
      const govOrPvt = [
        {
          value: 1,
          text: 'govt',
          text_en: 'govt',
          text_bn: 'সরকারী'
        },
        {
          value: 2,
          text: 'private',
          text_en: 'private',
          text_bn: 'ব্যক্তিগত'
        }
      ]
      const tmpList = govOrPvt.map((obj, index) => {
        if (this.$i18n.locale === 'bn') {
            return { value: obj.value, text: obj.text_bn, text_en: obj.text_en, text_bn: obj.text_bn }
        } else {
            return { value: obj.value, text: obj.text_en, text_en: obj.text_en, text_bn: obj.text_bn }
        }
      })
      return tmpList
    },
    fiscalYearList: function () {
      return this.$store.state.Portal.commonObj.fiscalYearList.filter(item => item.status === 0)
    }
  },
  watch: {
    'formData.org_id': function (newVal, oldVal) {
      if (newVal !== 0) {
        this.designationList = this.getDesignationByOrg(newVal)
        this.officeTypeList = this.getOfficeTypeList(newVal)
      }
    },
    'formData.office_type_id': function (newValue) {
      if (newValue !== 0) {
        this.officeList = this.getOfficeList(newValue)
      }
    }
  },
  mounted () {
    flatpickr('.fromDate', {})
  },
  methods: {
    async paymentStatusUpdate () {
      if (this.$route.query.transId) {
        const transId = this.$route.query.transId
        await RestApi.getData(trainingElearningServiceBaseUrl, duplicateCertificateApiPaymentCancel + '/' + transId).then(response => {
          if (response.success) {
            this.$toast.success({
              title: 'Success',
              message: response.message,
              color: '#D6E09B'
            })
          } else {
            this.$toast.error({
              title: 'Error',
              message: response.message
            })
          }
        })
        // this.$router.push(this.$route)
      } else {
        // this.$router.push('/training/duplicate-certificate')
      }
    },
    getOrgName (id) {
      const trainingType = this.$store.state.Portal.commonObj.organizationProfileList.find(item => item.value === id)
      if (this.$i18n.locale === 'bn') {
        return trainingType !== undefined ? trainingType.abbreviation_bn : ''
      } else {
        return trainingType !== undefined ? trainingType.abbreviation : ''
      }
    },
    getDesignation (id) {
      const trainingType = this.$store.state.Portal.commonObj.designationList.find(item => item.value === id)
      if (this.$i18n.locale === 'bn') {
        return trainingType !== undefined ? trainingType.abbreviation_bn : ''
      } else {
        return trainingType !== undefined ? trainingType.abbreviation : ''
      }
    },
    getOrgAddress (id) {
      const trainingType = this.$store.state.Portal.commonObj.organizationProfileList.find(item => item.value === id)
      if (this.$i18n.locale === 'bn') {
        return trainingType !== undefined ? trainingType.address_bn : ''
      } else {
        return trainingType !== undefined ? trainingType.address_en : ''
      }
    },
    getStatus (id) {
      const trainingType = this.statusList.find(job => job.value === parseInt(id))
        if (this.$i18n.locale === 'bn') {
          return trainingType !== undefined ? trainingType.text_bn : ''
        } else {
          return trainingType !== undefined ? trainingType.text_en : ''
        }
    },
    getJobType (id) {
      const trainingType = this.$store.state.TrainingElearning.commonObj.jobTypeList.find(job => parseInt(job.value) === parseInt(id))
        if (this.$i18n.locale === 'bn') {
          return trainingType !== undefined ? trainingType.text_bn : ''
        } else {
          return trainingType !== undefined ? trainingType.text_en : ''
        }
    },
    getApplicationSubject (id) {
      const trainingType = this.subjectTypeList.find(item => item.value === id)
        if (this.$i18n.locale === 'bn') {
          return trainingType !== undefined ? trainingType.text : ''
        } else {
          return trainingType !== undefined ? trainingType.text : ''
        }
    },
    getProfessionType (id) {
      const trainingType = this.professionTypeList.find(item => item.value === id)
        if (this.$i18n.locale === 'bn') {
          return trainingType !== undefined ? trainingType.text : ''
        } else {
          return trainingType !== undefined ? trainingType.text : ''
        }
    },
    EngBangNum (n) {
      if (this.$i18n.locale === 'bn') {
        return n.replace(/\d/g, d => '০১২৩৪৫৬৭৮৯'[d])
      } else {
        return n.replace(/[০-৯]/g, d => '০১২৩৪৫৬৭৮৯'.indexOf(d))
      }
    },
    getDesignationByOrg (orgId = null) {
      const desigList = this.$store.state.Portal.commonObj.designationList.filter(item => item.status === 0)
      if (desigList) {
          return desigList.filter(item => item.org_id === parseInt(orgId))
      }
      return desigList
    },
    getOfficeTypeList (orgId = null) {
      const officeTypeList = this.$store.state.Portal.commonObj.officeTypeList.filter(item => item.status === 0)
      if (orgId) {
        return officeTypeList.filter(office => office.org_id === orgId).map(obj => {
          if (this.$i18n.locale === 'bn') {
            return { value: obj.value, text: obj.text_bn }
          } else {
            return { value: obj.value, text: obj.text }
          }
        })
      }
      return officeTypeList
    },
    getOfficeList (orgId = null) {
      const officeList = this.$store.state.Portal.commonObj.officeList.filter(item => item.status === 0)
      if (orgId) {
        return officeList.filter(office => office.office_type_id === orgId).map(obj => {
          if (this.$i18n.locale === 'bn') {
            return { value: obj.value, text: obj.text_bn }
          } else {
            return { value: obj.value, text: obj.text }
          }
        })
      }
      return officeList
    },
    pdfExport () {
      this.$htmlToPaper('printData', this.pageStyle)
      // const reportTitle = this.$t('li_step.circular')
      // ExportPdf.exportPdfDetails(licenseRegistrationServiceBaseUrl, '/configuration/report-heading/detail', 3, reportTitle, this, this.details)
    }
  }
}
</script>
